/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*UDUCAT:GLP-2: 2025-03-31:1551_24*/
:root {
  --udu__less-version: "2025-03-31:1551_24";
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none !important;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
/* No Js */
.no-js .owl-carousel {
  display: block;
}
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}
/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			  This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			  calculation of the height of the owl-item that breaks page layouts
		   */
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.udu__prod-slider-itm-disp-flex .owl-item .item.udu__prod-gal-opacity-fixed-v2 {
  display: flex !important;
  opacity: 1 !important;
}
.udu__prod-slider-itm-disp-block .owl-item .item {
  display: block !important;
  opacity: 1 !important;
}
._ .udu__indexSliderControl:hover .owl-controls-auto,
._ .udu__indexSliderControl:hover .bx-controls-auto,
._ .udu__indexSliderControl:hover .nivo-controls-auto {
  transition: all 1s ease-out 0s;
  visibility: visible;
}
._ .udu__indexSliderControl:hover .owl-controls-auto a:not(.active),
._ .udu__indexSliderControl:hover .bx-controls-auto a:not(.active),
._ .udu__indexSliderControl:hover .nivo-controls-auto a:not(.active) {
  opacity: 1;
  transform: scale(1);
  z-index: 505;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 {
  bottom: 13px !important;
  display: inline-flex !important;
  height: auto;
  left: 50% !important;
  right: auto !important;
  top: auto !important;
  visibility: visible;
  width: auto;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a {
  border: 1px solid #fff !important;
  cursor: pointer;
  height: 25px !important;
  margin-left: 3px;
  opacity: 0.7 !important;
  position: relative !important;
  transform: scale(1);
  width: 25px !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.active {
  border: 1px solid #f00 !important;
  opacity: 1 !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-start::before {
  border-top: 7px solid transparent !important;
  border-bottom: 7px solid transparent !important;
  border-left: 11px solid rgba(255, 255, 255, 0.6) !important;
  right: 4px !important;
  top: 5px !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::after {
  left: 6px;
  top: 5px;
  display: block;
  height: 13px;
  width: 5px;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::after {
  left: auto;
  right: 5px;
  top: 5px;
}
._ .udu__indexSliderControl .owl-controls-auto,
._ .udu__indexSliderControl .bx-controls-auto,
._ .udu__indexSliderControl .nivo-controls-auto {
  height: 70px;
  position: absolute;
  right: calc(50% - 35px);
  top: calc(50% - 35px);
  width: 70px;
}
._ .udu__indexSliderControl .owl-controls-auto a,
._ .udu__indexSliderControl .bx-controls-auto a,
._ .udu__indexSliderControl .nivo-controls-auto a {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 0 !important;
  height: 70px;
  bottom: 0;
  opacity: 0;
  padding: 5px;
  position: absolute;
  right: 0;
  transform: scale(0.6);
  transition: all 0.3s ease-in-out 0s, opacity 1s ease-out 0s, scale 1s ease-out 0s;
  width: 70px;
  z-index: 0;
}
._ .udu__indexSliderControl .owl-controls-auto a:hover,
._ .udu__indexSliderControl .bx-controls-auto a:hover,
._ .udu__indexSliderControl .nivo-controls-auto a:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
._ .udu__indexSliderControl .owl-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-start::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-start::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-start::before {
  border-left: 30px solid rgba(255, 255, 255, 0.8);
}
._ .udu__indexSliderControl .owl-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .bx-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-stop::after,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-stop::after {
  background-color: rgba(255, 255, 255, 0.8);
}
._ .udu__indexSliderControl .owl-controls-auto a.active,
._ .udu__indexSliderControl .bx-controls-auto a.active,
._ .udu__indexSliderControl .nivo-controls-auto a.active {
  opacity: 0;
  transform: scale(1);
  z-index: 49;
}
._ .udu__indexSliderControl .owl-controls-auto a.play::before,
._ .udu__indexSliderControl .bx-controls-auto a.play::before,
._ .udu__indexSliderControl .nivo-controls-auto a.play::before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-start::before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-start::before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-start::before {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid rgba(255, 255, 255, 0.6);
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: all 0.3s ease-in-out 0s;
  width: 0;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop::before,
._ .udu__indexSliderControl .bx-controls-auto a.stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a.stop::before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .owl-controls-auto a.stop::after,
._ .udu__indexSliderControl .bx-controls-auto a.stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a.stop::after,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop::after,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop::after {
  background-color: rgba(255, 255, 255, 0.6);
  content: " ";
  display: block;
  height: 30px;
  position: absolute;
  transition: all 0.3s ease-in-out 0s;
  width: 10px;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop:before,
._ .udu__indexSliderControl .bx-controls-auto a.stop:before,
._ .udu__indexSliderControl .nivo-controls-auto a.stop:before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop:before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop:before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop:before {
  left: 20px;
  top: 20px;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop:after,
._ .udu__indexSliderControl .bx-controls-auto a.stop:after,
._ .udu__indexSliderControl .nivo-controls-auto a.stop:after,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop:after,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop:after,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop:after {
  right: 20px;
  top: 20px;
}
._ #slider.udu__mainBannerDotsPosition-LB .owl-dots {
  justify-content: flex-start;
}
._ #slider.udu__mainBannerDotsPosition-CB .owl-dots {
  justify-content: center;
}
._ #slider.udu__mainBannerDotsPosition-RB .owl-dots {
  justify-content: flex-end;
}
._ #slider.has-custom-height .owl-item img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}
._ #slider > li {
  position: relative;
}
._ #slider .text-widget-placeholder {
  display: block;
}
._ .owl-carousel .owl-nav .owl-prev,
._ .owl-carousel .owl-nav .owl-next {
  -webkit-text-size-adjust: none;
}
._ .owl-carousel .owl-item.active .owl-caption > div .h3 {
  opacity: 1 !important;
  transition: all 0.8s ease-in-out 0.2s !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div .slider-comment {
  opacity: 1 !important;
  transition: all 0.8s ease-in-out 0.6s !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div .button {
  opacity: 1 !important;
  transition: all 0.8s ease-in-out 1s, background-color 0.3s ease-in-out 0s, background-position 0s !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-1 .h3 {
  margin-top: 0 !important;
  transition: all 1s ease-in-out 0.4s !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-1 .slider-comment {
  margin-top: 0 !important;
  transition: all 0.8s ease-in-out 0.2s !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-1 .button {
  margin-top: 0 !important;
  transition: all 1.2s ease-in-out 0.8s, background-color 0.3s ease-in-out 0s, background-position 0s !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-2 .h3 {
  margin-left: 30px !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-2 .slider-comment {
  margin-left: 30px !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-2 .button {
  margin-left: 30px !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-3 .h3 {
  margin-left: 0 !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-3 .slider-comment {
  margin-left: 0 !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-3 .button {
  margin-left: 0 !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-4 .h3 {
  margin-right: 0 !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-4 .slider-comment {
  margin-right: 0 !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-4 .button {
  margin-right: 0 !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-5 .h3 {
  margin-top: 0 !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-5 .slider-comment {
  margin-top: 0 !important;
}
._ .owl-carousel .owl-item.active .owl-caption > div.effect-5 .button {
  margin-top: 0 !important;
}
._ .owl-carousel .owl-item .owl-caption > div {
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  left: 0;
  position: absolute;
  top: 20%;
  width: 100%;
  z-index: 1000;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-1 {
  align-items: flex-start;
  padding-left: 100px;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-1 .h3 {
  margin: 10px auto 30px 0;
  order: 2;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-1 .slider-comment {
  margin: 10px auto 30px 0;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-1 .button {
  margin: 30px auto 0 0;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-2 {
  align-items: flex-start;
  padding-left: 100px;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-2 .h3 {
  margin: 0 auto 20px 0;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-2 .slider-comment {
  margin: 0 auto 20px 0;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-2 .button {
  margin: 0 auto 40px 0;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-3 {
  align-items: flex-start;
  padding-left: 100px;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-3 .h3 {
  margin: 0 auto 20px 30px;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-3 .slider-comment {
  margin: 0 auto 20px 30px;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-3 .button {
  margin: 0 auto 40px 30px;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-4 {
  align-items: flex-end;
  padding-right: 100px;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-4 .h3 {
  margin: 0 30px 20px auto;
  text-align: right;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-4 .slider-comment {
  margin: 0 30px 20px auto;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-4 .button {
  margin: 0 30px 40px auto;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-5 {
  align-items: center;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-5 .h3 {
  align-items: center;
  margin: -30px auto 20px;
  text-align: center;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-5 .slider-comment {
  margin: -30px auto 20px;
}
._ .owl-carousel .owl-item .owl-caption > div.effect-5 .button {
  margin: -30px auto 40px;
}
._ .owl-carousel .owl-item .owl-caption > div .h3 {
  color: #ffffff;
  font-size: 45px;
  font-weight: 100;
  line-height: 1;
  opacity: 0;
  order: 1;
  text-shadow: 0 2px 2px #000;
}
._ .owl-carousel .owl-item .owl-caption > div .h3 span {
  display: block;
  font-weight: 700;
}
._ .owl-carousel .owl-item .owl-caption > div .slider-comment {
  color: #ffffff;
  font-size: 17px;
  font-style: italic;
  font-weight: 100;
  line-height: 1;
  opacity: 0;
  order: 2;
  text-transform: uppercase;
}
._ .owl-carousel .owl-item .owl-caption > div .button {
  color: #ffffff;
  line-height: normal !important;
  opacity: 0;
  order: 3;
  width: auto;
}
._ .owl-carousel .owl-item .owl-caption > div .button > * {
  font-weight: 700;
}
@media (max-width: 1200px) {
  ._ #slider .owl-caption .h3 {
    font-size: 34px;
  }
  ._ #slider .owl-caption .slider-comment {
    font-size: 14px;
  }
}
@media (max-width: 1050px) {
  ._ #slider .owl-caption .h3 {
    font-size: 28px;
  }
  ._ #slider .owl-caption .slider-comment {
    font-size: 12px;
  }
}
@media (max-width: 1000px) {
  ._ #slider .owl-caption .h3 {
    font-size: 28px;
  }
  ._ #slider .owl-caption .slider-comment {
    font-size: 12px;
  }
  ._ #slider .owl-caption .button {
    display: none;
  }
}
@media (max-width: 600px) {
  ._ #slider .owl-dots {
    display: none;
  }
  ._ #slider .owl-caption {
    top: 10%;
  }
  ._ #slider .owl-caption .h3 {
    font-size: 18px !important;
  }
}
._ ul.nivoSlider {
  display: none;
  height: auto !important;
  margin: 0 auto;
  max-width: 1450px;
  padding: 20px 10px 10px;
  position: relative;
}
._ ul.nivoSlider._done {
  display: block;
}
._ ul.nivoSlider .udu__owl-items-count-0 {
  min-height: 200px;
}
._ ul.nivoSlider li {
  overflow: hidden;
}
._ ul.nivoSlider li .owl-nav button.owl-prev,
._ ul.nivoSlider li .owl-nav button.owl-next {
  background-color: #34373c;
  background-image: url(./img/arrow-medium.png);
  background-position: -12px 7px;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 70px;
  margin-top: -35px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transition: all 0.4s ease-in-out;
  width: 35px;
  z-index: 1000;
}
._ ul.nivoSlider li .owl-nav button.owl-prev {
  border-radius: 0 5px 5px 0;
  left: -40px;
}
._ ul.nivoSlider li .owl-nav button.owl-next {
  background-position: -10px -50px;
  border-radius: 5px 0 0 5px;
  right: -40px;
}
._ ul.nivoSlider li .owl-carousel {
  position: relative;
}
._ ul.nivoSlider li .owl-carousel:hover .owl-nav button.owl-prev {
  left: -2px;
}
._ ul.nivoSlider li .owl-carousel:hover .owl-nav button.owl-next {
  right: -2px;
}
._ ul.nivoSlider li .owl-carousel .owl-dots {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
}
._ ul.nivoSlider li .owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  margin: 0 7px;
  vertical-align: middle;
}
._ ul.nivoSlider li .owl-carousel .owl-dots .owl-dot.active span,
._ ul.nivoSlider li .owl-carousel .owl-dots .owl-dot:hover span {
  background-color: #34373c;
}
._ ul.nivoSlider li .owl-carousel .owl-dots .owl-dot span {
  background-color: #a3a3a3;
  border-radius: 50%;
  display: block;
  height: 12px;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 12px;
}
._ ul.nivoSlider li .owl-carousel .owl-item {
  cursor: pointer;
  width: 100%;
}
._ .index-slider {
  max-width: 1600px;
  margin: 0 auto 40px;
  min-height: 100px;
  /* Product Mode */
}
@media (max-width: 1600px) {
  ._ .index-slider {
    padding: 0 10px;
  }
}
._ .index-slider.product-carousel .owl-carousel .owl-item {
  border-radius: 5px;
  height: 350px;
}
._ .index-slider.product-carousel .owl-carousel .owl-item:hover .item img {
  transform: none;
}
._ .index-slider.product-carousel .owl-carousel .owl-item .item:before,
._ .index-slider.product-carousel .owl-carousel .owl-item .item:after {
  display: none;
}
._ .index-slider.product-carousel .owl-carousel .owl-item .item .item-prod-carousel {
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 10px 5px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease-in-out 0s;
  width: 100%;
}
._ .index-slider.product-carousel .owl-carousel .owl-item .item .item-prod-carousel .image {
  align-items: center;
  display: flex;
  height: 180px;
  justify-content: center;
  overflow: hidden;
  padding-top: 50px;
  position: relative;
  width: 100%;
}
._ .index-slider.product-carousel .owl-carousel .owl-item .item .item-prod-carousel .image img {
  height: fit-content;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.3s;
}
._ .index-slider.product-carousel .owl-carousel .owl-item .item .item-prod-carousel .name {
  margin-top: 20px;
  order: 20;
  text-align: center;
}
._ .index-slider.product-carousel .owl-carousel .owl-item .item .item-prod-carousel .sku {
  margin: auto 0 0;
  order: 30;
}
._ .index-slider.product-carousel .owl-carousel .owl-item .item .item-prod-carousel .price {
  background-color: #ccccccc4;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  color: #fff;
  height: auto;
  left: 10%;
  padding: 10px 20px 10px 10px;
  position: absolute;
  right: 10%;
  text-align: center;
  text-shadow: 0 0 2px #000;
  top: 0;
  transition: all 0.5s ease-in-out;
}
._ .index-slider.product-carousel .owl-carousel .owl-item .item .item-prod-carousel .price .money {
  color: var(--schema-color);
}
._ .index-slider .owl-carousel .owl-item {
  border: 1px solid #eee;
  box-sizing: border-box;
  height: 265px;
  margin-bottom: 5px;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
}
._ .index-slider .owl-carousel .owl-item:hover .item:before {
  animation: udu__pulse 1s infinite;
  height: 80px;
  margin-left: -40px;
  margin-top: -40px;
  opacity: 1;
  width: 80px;
}
._ .index-slider .owl-carousel .owl-item:hover .item:after {
  opacity: 1;
  transform: rotate3d(0, 0, 0, -100deg);
}
._ .index-slider .owl-carousel .owl-item:hover .item img {
  transform: scale(1.2);
}
._ .index-slider .owl-carousel .owl-item .item {
  align-items: center;
  background-color: #eee;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
._ .index-slider .owl-carousel .owl-item .item:before {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  box-shadow: 0 0 9px rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
  content: "\ec17";
  display: flex;
  font-family: Linearicons;
  font-size: 30px;
  height: 10px;
  justify-content: center;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 10px;
  transition: all 0.35s ease-in-out;
  z-index: 100;
}
._ .index-slider .owl-carousel .owl-item .item:after {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 10px;
  content: "";
  left: 10px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 10px;
  transform: rotate3d(1, 1, 0, 100deg);
  transition: all 0.35s ease-in-out;
}
._ .index-slider .owl-carousel .owl-item .item > a {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
._ .index-slider .owl-carousel .owl-item .item .nivo-add-html {
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  color: #444;
  display: flex;
  height: 65px;
  justify-content: center;
  left: 0;
  padding: 8px;
  position: absolute;
  right: 0;
}
._ .index-slider .owl-carousel .owl-item .item img {
  max-height: 90%;
  max-width: 95%;
  mix-blend-mode: multiply;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  width: auto !important;
}
._ .index-slider .owl-carousel .owl-nav button {
  background: rgba(255, 255, 255, 0.8) url("../../img/slider-arrow-2.png") 10px -3px no-repeat;
  box-sizing: border-box;
  height: 50px;
  left: -7px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  width: 50px;
}
._ .index-slider .owl-carousel .owl-nav button.owl-next {
  background-position: -60px -3px;
  left: auto;
  right: -7px;
}
._ #blog-slider {
  box-sizing: border-box;
  min-height: 200px;
  padding: 0 10px;
  position: relative;
  width: 100%;
}
._ #blog-slider .item div {
  display: flex;
  justify-content: center;
  align-items: center;
}
._ #blog-slider .owl-dots {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
}
._ #blog-slider .owl-dots .owl-dot {
  background-color: #a3a3a3;
  border-radius: 50%;
  height: 12px;
  margin: 0 3px;
  padding: 0 !important;
  width: 12px;
}
._ #blog-slider .owl-dots .owl-dot.active,
._ #blog-slider .owl-dots .owl-dot:hover {
  background-color: #34373c;
  transition: all 0.4s ease-in-out;
}
._ .category-list.owl-carousel {
  display: block;
}
